/**
 * Remove duplicated values of array.
 *
 * @param {Array<any>} array - The array to remove duplicated values.
 * @returns {Array<any>} -  A new array with uniq values, in the same order.
 *
 * @example
 * const input = [1, 2, 2, 3];
 * const result = uniq(input);
 * console.log(result); // [1, 2, 3]
 *
 * @example
 * const input = ['a', 'b', 'a'];
 * const result = uniq(input);
 * console.log(result); // ['a', 'b']
 */
export default (array: Array<any>) => {
  return [...new Set(array)]
}
