import type { IOCRResponse, IProcedureResponse, IUseProcedures } from '@/types/types'
import { EventName, SessionStorageKey } from '@/types/enums'

export const useProcedureSlug = () => useState<string[]>('slugs', () => [])

export default function (): IUseProcedures {
  const { data, error, status } = useAsyncData<IProcedureResponse[]>('procedure-display', async () => {
    let response = await $fetch<IProcedureResponse[]>('/api/procedure/display', {
      method: 'POST',
      body: { procedures: useProcedureSlug().value },
    })

    response = snakeToCamel(response)

    if (import.meta.client && isNewJourney()) {
      useSessionStorage(SessionStorageKey.ProceduresSearched, '').value = JSON.stringify(response.map((p) => {
        return {
          procedureId: p.procedure.id,
          name: p.procedure.shortName,
          slug: p.procedure.slug,
        }
      }))
    }

    return response
  }, {
    dedupe: 'defer',
    watch: [useProcedureSlug()],
  })

  function addSlug(slug: string | string[]) {
    const oldSlugs = useProcedureSlug().value

    if (Array.isArray(slug))
      useProcedureSlug().value = uniq([...oldSlugs, ...slug]).map((e: string) => e.replace(/_DESKTOP/g, ''))

    else if (!oldSlugs.includes(slug))
      useProcedureSlug().value = [...oldSlugs, slug].map(e => e.replace(/_DESKTOP/g, ''))
  }

  async function submitOCR(file: File): Promise<IOCRResponse> {
    const baseURL = useRuntimeConfig().public.api.awsGateway
    const formData = new FormData()
    formData.append('file', file)

    let response = await $fetch<IOCRResponse>('/v1/ocr/submit', {
      baseURL,
      method: 'POST',
      body: formData,
    })

    response = snakeToCamel(response)

    if (response.procedures?.length) {
      track(EventName.OCRUpload, {
        procedures: response.procedures?.length,
      })
      addSlug(response.procedures.map(proc => proc.displaySlug))
    }

    return response
  }

  async function getPrescriptionIds(customerId: string, prescriptionHashes: string[]): Promise<string[]> {
    if (!prescriptionHashes.length || !customerId)
      return []

    const baseURL = useRuntimeConfig().public.api.awsGateway
    return await $fetch<string[]>('/v1/sales-order/orders/associate-prescriptions', {
      baseURL,
      method: 'POST',
      body: {
        customer_id: customerId,
        prescription_hashes: prescriptionHashes,
      },
    })
  }

  return {
    addSlug,
    removeSlug(slug: string) {
      const oldSlugs = useProcedureSlug().value

      useProcedureSlug().value = oldSlugs.filter(s => s !== slug)
    },
    status,
    procedures: computed(() => data.value || []),
    error: computed(() => error.value || []),
    submitOCR,
    getPrescriptionIds,
  }
}
